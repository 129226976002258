// extracted by mini-css-extract-plugin
export var articlesLink = "style-module--articles-link--a378f";
export var backgroundRubBottom = "style-module--background-rub-bottom--05942";
export var backgroundRubTop = "style-module--background-rub-top--a06f0";
export var checkButton = "style-module--check-button--fa873";
export var checkButtonLoading = "style-module--check-button-loading--d3f3c";
export var checkButtonResult = "style-module--check-button-result--39a60";
export var checkInputDate = "style-module--check-input-date--dbf16";
export var checkInputInn = "style-module--check-input-inn--3ca16";
export var checkInputs = "style-module--check-inputs--c18f2";
export var container = "style-module--container--a1154";
export var containerFaq = "style-module--container-faq--2c28c";
export var containerMain = "style-module--container-main--4b53a";
export var description = "style-module--description--34d93";
export var faqCard = "style-module--faq-card--d633b";
export var gradient = "style-module--gradient--267a0";
export var gradientMobile = "style-module--gradient-mobile--25038";
export var textResult = "style-module--text-result--02c45";
export var textResultError = "style-module--text-result-error--d739c";
export var textResultSuccess = "style-module--text-result-success--b17b5";
export var title = "style-module--title--4019c";