import React, { useState } from "react";
import { Helmet } from "react-helmet";
import * as styles from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";

import { ButtonUI, InputUI, TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { Layout } from "@components";
import { useSendEvent } from "@tools/hooks";

const checkInn = (inn, requestDate) => {
  return fetch("https://statusnpd.nalog.ru/api/v1/tracker/taxpayer_status", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ inn, requestDate }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        throw error.message && error.message.message
          ? error.message.message
          : error.message;
      }
    );
};

const CheckInnPage = () => {
  const { clickCheckInn } = useSendEvent();

  const [inn, setInn] = useState<string>("");
  const [date, setDate] = useState<any>(new Date().toISOString().split("T")[0]);
  const [message, setMessage] = useState<{ text: string; status: boolean }>({
    text: "",
    status: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const checkInnHandler = async () => {
    if (inn.length !== 12) {
      setMessage({ text: "Введите корректный ИНН", status: false });
      return;
    }
    if (new Date(date).toString() == "Invalid Date") {
      setMessage({ text: "Выберите дату", status: false });
      return;
    }
    try {
      setLoading(true);
      const res = await checkInn(inn, date);
      setMessage({ text: res.message, status: res.status === true });
      clickCheckInn();
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Проверка статуса самозанятого по ИНН на сайте, поиск в реестре самозанятых граждан по фамилии
        </title>
        <meta
          name="description"
          content={`Проверить самозанятого по ИНН через базу ФНС. Актуальный реестр самозанятых граждан за ${new Date().getFullYear()} год. Узнать статус НПД: действительно ли и не утрачен. Быстро и удобно`}
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/check-inn"
        />
      </Helmet>
      <Layout>
        <div className={styles.container}>
          <div className={styles.containerMain}>
            <TypographyUI.H1
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >Проверьте статус самозанятого по ИНН</TypographyUI.H1>

            <div className={styles.checkInputs}>
              <div className={styles.checkInputInn}>
                <InputUI.INN
                  onChange={setInn}
                  value={inn}
                  placeholder="12 цифр"
                  labelText="ИНН"
                />
              </div>

              <div className={styles.checkInputDate}>
                <InputUI.Root
                  type="date"
                  onChange={setDate}
                  value={date}
                  placeholder="Сегодня"
                  labelText="На дату"
                />
              </div>
            </div>

            <div className={styles.checkButtonResult}>
              <ButtonUI
                className={`${styles.checkButton} ${
                  loading ? styles.checkButtonLoading : ""
                }`}
                onClick={checkInnHandler}
                disabled={!inn || inn?.length < 12 || !date}
                $w="280px"
              >
                Узнать статус
              </ButtonUI>
              <TypographyUI.CaptionMedium
                className={`${styles.textResult} ${
                  message.status === true
                    ? styles.textResultSuccess
                    : styles.textResultError
                }`}
              >
                {message.text}
              </TypographyUI.CaptionMedium>
            </div>
          </div>
          <div className={styles.containerFaq}>
            <StaticImage
              className={styles.backgroundRubTop}
              src="../../images/check-inn/background-rub-1.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={85}
              height={120}
              layout="fixed"
              objectFit="contain"
              alt="background-rub-1"
            />
            <StaticImage
              className={styles.backgroundRubBottom}
              src="../../images/check-inn/background-rub-2.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={75}
              height={85}
              layout="fixed"
              objectFit="contain"
              alt="background-rub-2"
            />
            <div className={styles.gradient}>
              <StaticImage
                src="../../images/check-inn/gradient.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={905}
                height={720}
                layout="fixed"
                objectFit="contain"
                alt="gradient-img"
              />
            </div>
            <div className={styles.gradientMobile}>
              <StaticImage
                src="../../images/check-inn/gradient-mobile.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={335}
                height={336}
                layout="fixed"
                objectFit="contain"
                alt="gradient-img"
              />
            </div>
            <div className={styles.faqCard}>
              <TypographyUI.Body1Regular
                swichPoint={LAPTOP_MOBILE_POINTER}
                className={styles.title}
              >
                Что нужно знать перед началом работы с самозанятыми
              </TypographyUI.Body1Regular>
              <TypographyUI.Body2Regular className={styles.description}>
                Физические лица, юридические лица и индивидуальные
                предприниматели освобождены от уплаты налогов при работе с
                самозанятыми.
              </TypographyUI.Body2Regular>
              <TypographyUI.Body2Regular className={styles.description}>
                Однако перед началом работы необходимо проверить статус
                исполнителя по ИНН. Если статус самозанятого не подтвержден —
                компании придется уплатить за исполнителя налоги и страховые
                взносы.
              </TypographyUI.Body2Regular>
            </div>
            <div className={styles.faqCard}>
              <TypographyUI.Body1Regular
                swichPoint={LAPTOP_MOBILE_POINTER}
                className={styles.title}
              >
                Какие документы должен предоставить самозанятый
              </TypographyUI.Body1Regular>
              <TypographyUI.Body2Regular className={styles.description}>
                Перед оплатой попросите самозанятого выставить счет через
                мобильное приложение «Мой налог», а затем потребуйте чек,
                подтверждающий оплату. Эти документы потребуются бухгалтерии при
                подготовке отчетности.
              </TypographyUI.Body2Regular>
            </div>
            <a
              className={styles.articlesLink}
              href="https://самозанятые.рф/blog/"
            >
              <TypographyUI.Body1Medium className={styles.articlesLink}>
                Другие статьи по теме
              </TypographyUI.Body1Medium>
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CheckInnPage;
